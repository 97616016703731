import React, { useState } from 'react';
import '../stylesheets/MonCompte.css';
import { IconContext } from "react-icons";
import { NavLink, useLocation } from 'react-router-dom';
import { CgProfile } from "react-icons/cg";
import { TiContacts } from "react-icons/ti";

const MonCompte = () => {
    const [visible, setVisible] = useState(true);
    const location = useLocation(); 

    const handleClose = () => {
        setVisible(false);
    };

    const handleOpen = () => {
        setVisible(true);
        console.log(location)
    };

    return (
        <div className={`popup ${visible ? 'show' : 'hide'}`}>
            {visible && (
                <div className="popup-header">
                    <button className="close-btn" onClick={handleClose}>&times;</button>
                </div>
            )}

            <div className="popup-content">
                <ul>
                    {location.pathname === "/contactsurgence" ?

                        <li className='popup-content-uniq-active'>
                            <NavLink to="/contactsurgence">
                                <IconContext.Provider value={{ color: "#B4B4B4", size: 20, style: { marginRight: "2%" } }}>
                                    <TiContacts />
                                </IconContext.Provider>
                                Contacts d’urgence
                            </NavLink>
                        </li>
                        :
                        <li className='popup-content-uniq'>
                            <NavLink to="/contactsurgence">
                                <IconContext.Provider value={{ color: "#B4B4B4", size: 20, style: { marginRight: "2%" } }}>
                                    <TiContacts />
                                </IconContext.Provider>
                                Contacts d’urgence
                            </NavLink>
                        </li>

                    }
                    {location.pathname === "/monprofil" ?

                        <li className='popup-content-uniq-active'>
                            <NavLink to="/monprofil" >
                                <IconContext.Provider value={{ color: "#B4B4B4", size: 20, style: { marginRight: "2%" } }}>
                                    <CgProfile />
                                </IconContext.Provider>
                                Mon profil
                            </NavLink>
                        </li>
                        :
                        <li className='popup-content-uniq'>
                            <NavLink to="/monprofil" >
                                <IconContext.Provider value={{ color: "#B4B4B4", size: 20, style: { marginRight: "2%" } }}>
                                    <CgProfile />
                                </IconContext.Provider>
                                Mon profil
                            </NavLink>
                        </li>

                    }
                </ul>
                
            </div>
            {!visible && (
                <div className="reopen-btn" onClick={handleOpen}>
                    &raquo;
                </div>
            )}
        </div>
    );
};

export default MonCompte;
