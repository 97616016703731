// LoginModal.js
import React, { useState } from 'react';
import Modal from 'react-modal';
import '../stylesheets/LoginModal.css';
import Logo from "../assets/lanterne.png"
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { MdMailOutline } from "react-icons/md";
import { IoLockClosedOutline } from "react-icons/io5";
import { IconContext } from "react-icons";
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from '../scripts/firebase';


Modal.setAppElement('#root');

const LoginModal = ({ isOpen, onRequestClose, onLogin }) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        try {
            await signInWithEmailAndPassword(auth, username, password);
            onLogin();
            onRequestClose();
        } catch (error) {
            setError('Échec de la connexion');
            console.error("Error logging in: ", error);
        }
    };

    const toggleShowPassword = () => {
        setShowPassword(!showPassword);
    };


    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Login Modal"
            className="modal"
            overlayClassName="modal-overlay"
        >
            <img src={Logo} alt="Logo lanterne" class="lanterne-logo" />
            <form onSubmit={handleSubmit}>
                <div>
                    <IconContext.Provider value={{ color: "white", style: { verticalAlign: 'middle' } }}>
                        <>
                            <MdMailOutline />
                        </>
                    </IconContext.Provider>
                    <input className='input'
                        placeholder="E-mail / Nom d'utilisateur"
                        variant="standard"
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <IconContext.Provider value={{ color: "white", style: { verticalAlign: 'middle' } }}>
                        <>

                            <IoLockClosedOutline />
                        </>
                    </IconContext.Provider>
                    <input
                        className='input'
                        placeholder="Mot de passe"
                        type={showPassword ? 'text' : 'password'}
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <span onClick={toggleShowPassword} style={{ cursor: 'pointer' }}>
                        {showPassword ? <FaEyeSlash style={{ color: "white" }} /> : <FaEye style={{ color: "white" }} />}
                    </span>

                </div>
                {error && <p className="error">{error}</p>}
                <button type="submit" className='submit-btn'>Se connecter</button>
            </form>
        </Modal>
    );
};

export default LoginModal;
