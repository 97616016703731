import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import '../stylesheets/navbar.css'
import { RxHamburgerMenu } from "react-icons/rx";
import Logo from "../assets/lanterne.png"
import { IconContext } from "react-icons";
import { CgProfile } from "react-icons/cg";
import LoginModal from './LoginModal';

const Navbar = () => {
    const [showNavbar, setShowNavbar] = useState(false)
    const [login, setLogin] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleShowNavbar = () => {
        setShowNavbar(!showNavbar)
    }

    const handleLogout = () => {
        setLogin(false)
    }

    const handleLogin = () => {
        setLogin(true)
    }

    const openModal = () => {
        setIsModalOpen(true);
    }

    const closeModal = () => {
        setIsModalOpen(false);
    }


    return (
        <nav className="navbar">
            <div className="container">
                <div className="logo">
                    <NavLink to="/home">
                        <img src={Logo} alt="Logo lanterne" class="responsive-image" />
                    </NavLink>
                </div>
                <div className="nav-right">
                    {login ?
                        <>
                            <div className={`nav-elements  ${showNavbar && 'active'}`}>
                                <ul>
                                    <li>
                                        <NavLink to="/contactsurgence">
                                            <IconContext.Provider value={{ color: "#B4B4B4", size: 20, style: { verticalAlign: 'middle', marginRight: "6%" } }}>
                                                <>
                                                    <CgProfile />
                                                </>
                                            </IconContext.Provider>
                                            Mon compte
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="connection-button">
                                <button onClick={handleLogout} className="connection-link">Déconnexion</button>
                            </div>
                            <IconContext.Provider value={{ color: "white" }}>
                                <div className="menu-icon" onClick={handleShowNavbar}>
                                    <RxHamburgerMenu />
                                </div>
                            </IconContext.Provider>
                        </>

                        : <div className="connection-button">
                            <button onClick={openModal} className="connection-link">Connexion</button>
                        </div>
                    }
                </div>
            </div>
            <LoginModal isOpen={isModalOpen} onRequestClose={closeModal} onLogin={handleLogin} />
        </nav >
    )
}

export default Navbar
