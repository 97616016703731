import React, { useState } from 'react';
import "../stylesheets/ContactsUrgence.css"
import "../stylesheets/MonProfil.css"
import { IconContext } from "react-icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPencilAlt } from "react-icons/fa";
import { FaCheck } from "react-icons/fa6";

function InfoItem({ dataName, category, item, editInfos }) {
    const [isEditing, setIsEditing] = useState(false);
    const [editedItem, setEditedItem] = useState(item);

    const handleEditChange = (e) => {
        const { name, value } = e.target;
        setEditedItem({ ...editedItem, [name]: value });
        console.log(editedItem)
    };

    const saveEdit = () => {
        editInfos(dataName, editedItem.name);
        setIsEditing(false);
    };

    return (
        <div className='contact-content'>
            {isEditing ? (
                <div>
                    <div className="form-group-profil">
                        <label>{category} :</label>
                        <input
                            name="name"
                            className='input-profil-edit'
                            value={editedItem.name}
                            onChange={handleEditChange}
                        />

                        <button className="btn-profil" onClick={saveEdit}><FaCheck /></button>
                    </div>
                </div>
            ) : (
                <div>
                    <div className="form-group-profil">
                        <label>{category} :</label>
                        <input
                            name="name"
                            className='input-profil-unedit'
                            value={item}
                            readOnly
                        />
                        <button className="btn-profil" onClick={() => setIsEditing(true)}><FaPencilAlt /></button>
                    </div>
                </div>
            )}

        </div>
    );
}

export default InfoItem;
