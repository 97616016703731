import React from 'react';
import ContactItem from './ContactItem';

function ContactList({ contacts, deleteContact, updateContact }) {
  return (
    <div>
      {contacts.map((contact, index) => (
        <ContactItem 
          key={index} 
          index={index} 
          contact={contact} 
          deleteContact={deleteContact} 
          updateContact={updateContact}
        />
      ))}
    </div>
  );
}

export default ContactList;
