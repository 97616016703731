import React from 'react';
import "../stylesheets/Home.css"
import Logo from "../assets/lanterne.png"

export default function Home() {
  return (
    <div className='container-home'>
      <div className='first-content'>
        <img src={Logo} alt='logo lanterne' className='home-image' />
        <div className='header-title'>
          Lanterne
        </div>
      </div>
      <div className='content'>
        <div className='header-subtitle'>
          la solution qui éclairera votre chemin dans la nuit
        </div>
      </div>
      <div className='content'>
        <div className='header-text'>
          Lanterne est une application à 100% gratuite de génération d'itinéraires <br />plus sécuritaires et rassurants pour les piétons<br />afin de réduire le sentiment d'insécurité dans les rues
        </div>
      </div>
      <div className='content'>
        <div className='header-contact'>
          <a
            href="https://www.instagram.com/lanterne_officiel/"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbfe70fcf5a0514c5b1da_Instagram%20Icon.svg"
              width="20"
              alt="Instagram icon"
              style={{marginRight: "45px"}}
            />
          </a>
          <a
            href="https://www.linkedin.com/in/projet-lanterne-561408260/"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dc0002f2b676eb4ba0869_LinkedIn%20Icon.svg"
              width="20"
              alt="LinkedIn Icon"
              style={{marginRight: "45px"}}
            />
          </a>
          <a
            href="https://twitter.com/LanterneOff"
            target="_blank"
            className="footer-social-network-icons w-inline-block"
          >
            <img
              src="https://uploads-ssl.webflow.com/5966ea9a9217ca534caf139f/5c8dbf0a2f2b67e3b3ba079c_Twitter%20Icon.svg"
              width="20"
              alt="Twitter icon"
            />
          </a>
        </div>
      </div>
      <div className='info-box'>
        <p className='question' >Qu’est-ce que le bouton d’urgence de Lanterne ?</p>
        <p className='answer' >Le bouton d’urgence est une fonctionnalité de notre application mobile.
          Elle permet au utilisateur se sentant en danger de partager ses coordonnées GPS à ses contacts d'urgence en cas de besoin.
          Lorsque le bouton d’urgence est activé, un lien vers une page temporaire de ce site est directement envoyé à son contact.
        </p>
        <p className='question'>Qu’est-ce que le site Lanterne urgence ?</p>
        <p className='answer'>Le site Lanterne d’urgence vous permet de suivre la position de votre proche lorsque celui-ci vous envois un message d’urgence.
          De plus, si vous avez un compte Lanterne ce site web vous permettra d’apporter des modifications de votre compte.</p>
      </div>
    </div>

  );
}



