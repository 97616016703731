import React, { useState, useEffect } from 'react';
import MonCompte from '../components/MonCompte';
import { IoLockClosedSharp } from "react-icons/io5";
import "../stylesheets/ContactsUrgence.css"
import { IconContext } from "react-icons";
import InfoList from '../components/InfoList';
import { RiDeleteBin6Line } from "react-icons/ri";
import { auth, db } from '../scripts/firebase';
import { deleteUser } from 'firebase/auth';
import { doc, deleteDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'

export default function MonProfil() {

  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  
  useEffect(() => {
    const user = auth.currentUser;

    if (!user) {
      navigate('/home');
    }
  }, [navigate]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }

  }, [isAuthenticated])

  const deleteAccount = async () => {
    try {
      const user = auth.currentUser;
      if (user) {
        setIsAuthenticated(true)
        const uid = user.uid;

        await deleteUser(user);
        console.log("User deleted from Firebase Authentication");

        const userDocRef = doc(db, 'users', uid);
        await deleteDoc(userDocRef);
        console.log("User document deleted from Firestore");

        navigate('/home');
      } else {
        setIsAuthenticated(false)
        console.log("Aucun utilisateur n'est connecté");
      }
    } catch (error) {
      console.error("Error deleting account:", error);
      if (error.code === 'auth/requires-recent-login') {
        alert('Veuillez vous reconnecter pour supprimer votre compte.');
        navigate('/home');
      }
    }
  };


  return (
    <div className='container-contact'>
      {!isAuthenticated ? (
        <div className="error-message">
          Merci de vous connecter
        </div>
      ) : (
        <>
          <MonCompte></MonCompte>
          <h2 className='title-content'>Informations personnelles
            <IconContext.Provider value={{ color: "#FDAA41" }}>
              <>
                <IoLockClosedSharp />
              </>
            </IconContext.Provider>
          </h2>
          <InfoList></InfoList>
          <div className='del-content'>
            <h2 className='title_del'>Supprimer votre compte
              <IconContext.Provider value={{ color: "grey" }}>
                <>
                  <RiDeleteBin6Line />
                </>
              </IconContext.Provider>
            </h2>
            <p className='text_del'>Votre compte sera définitivement supprimé de l’application.<br></br>Toutes vos données seront perdues.</p>
            <button className="btn-del" onClick={() => deleteAccount()}>Supprimer le compte</button>
          </div>
        </>
      )}
    </div>
  );
}



