import React, { useState } from 'react';
import "../stylesheets/ContactsUrgence.css"
import '../stylesheets/LoginModal.css';

function AddContactPopup({ addContact, closePopup }) {
  const [contact, setContact] = useState({ name: '', phone: '' });
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
;

  const isValidPhoneNumber = (number) => {
    const phoneRegex = /^\+?\d{10,}$/;
    return phoneRegex.test(number);
  };



  const handleChange = (e) => {
    const { name, value } = e.target;
    setContact({ ...contact, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!isValidPhoneNumber(contact.phone)) {
      setErrorMessage('Veuillez entrer un numéro de téléphone valide');
      return; 
    }
    setErrorMessage('');
    addContact(contact);
    closePopup();

  };

  return (
    <div className="popup-contact">
      <button className="close-btn-contact" onClick={closePopup}>&times;</button>
      <h2 style={{ color: "white" }}>Ajouter un contact</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <input
            className='input-contact'
            placeholder='Nom'
            name="name"
            value={contact.name}
            onChange={handleChange}
            required
          />
        </label>
        <label>
          <input
            className='input-contact'
            name="phone"
            placeholder='Numéro de téléphone'
            value={contact.phone}
            onChange={handleChange}
            required
          />
        </label>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button className="submit-contact" type="submit">Valider</button>
      </form>
    </div>
  );
}

export default AddContactPopup;
