import React, { useEffect, useState, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import func from '../scripts/adress.js';
import 'leaflet-routing-machine';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import L from 'leaflet';
import '../stylesheets/Map.css';
import { lireDonneesFirebase } from '../scripts/firebase.js';
import 'leaflet-routing-machine';
import { useNavigate } from 'react-router-dom';

export default function MapWithPopup() {
  const [street, setStreet] = useState("");
  const mapRef = useRef(null);
  const [routingControl, setRoutingControl] = useState(null);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setData] = useState(null);
  const [tmp, setTmp] = useState([null, null]);
  const navigate = useNavigate();


  useEffect(() => {

    const fetchLocation = () => {
      navigator.geolocation.getCurrentPosition(
        position => {
          setTmp([position.coords.latitude, position.coords.longitude]);
        },
        error => {
          console.error('Erreur lors de la récupération de la localisation :', error);
          setTmp([null, null]);
        }
      );
    };

    fetchLocation();

    const intervalId = setInterval(fetchLocation, 5000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.size < 1) {
      navigate('/home');
      return;
    }

  }, [navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const queryParams = new URLSearchParams(window.location.search);
        if (queryParams.size < 1) {
          return
        }
        const result = await lireDonneesFirebase(queryParams.get("ID"));

        setData(result);
      } catch (error) {
        console.error('Erreur lors de la récupération des données Firebase :', error);
      }
    };

    fetchData();

    const intervalId = setInterval(fetchData, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const afficherItineraire = () => {
    if (tmp[0] != null && tmp[1] != null && data && data.lat != null && data.lng != null) {
      if (routingControl) {
        mapRef.current.removeControl(routingControl);
        setRoutingControl(null);
      } else {
        const waypoints = [L.latLng(tmp), L.latLng(data.lat, data.lng)];
        if (data.lat2 !== 0 && data.lng2 !== 0) {
          waypoints.push(L.latLng(data.lat2, data.lng2));
        }

        const route1 = L.Routing.control({
          waypoints: waypoints.slice(0, 2),
          lineOptions: {
            styles: [{ color: 'red' }]
          }
        });

        let route2;
        if (waypoints.length === 3) {
          route2 = L.Routing.control({
            waypoints: [waypoints[1], waypoints[2]],
            lineOptions: {
              styles: [{ color: 'blue' }]
            },
            show: false
          }).addTo(mapRef.current);
        }

        const newRoutingControl = L.layerGroup([route1.addTo(mapRef.current)]);
        if (route2) {
          newRoutingControl.addLayer(route2.addTo(mapRef.current));
        }

        setRoutingControl(newRoutingControl);
      }
      setButtonClicked(true);
    } else {
      console.error("Les coordonnées ou les données sont manquantes ou nulles");
    }
  };

  useEffect(() => {
    if (data) {
      func.GetCurrentAddress(data.lat, data.lng)
        .then(address => {
          setStreet(address);
        })
        .catch(error => {
          console.error('Erreur lors de la récupération de l\'adresse :', error);
          setStreet("Erreur lors de la récupération de l'adresse");
        });
    }
  }, [data]);

  if (data && tmp[0] != null && tmp[1] != null) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <MapContainer center={[data.lat, data.lng]} zoom={15} scrollWheelZoom={false} style={{ width: '100%', height: '100%' }} ref={mapRef}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={tmp}>
            <Popup>
              Vous êtes ici.
            </Popup>
          </Marker>
          <Marker position={[data.lat, data.lng]}>
            <Popup>
              {data.nom} se trouve : {street}
            </Popup>
          </Marker>
          {routingControl && (
            <>
              <Marker position={tmp}>
                <Popup>
                  Vous êtes ici.
                </Popup>
              </Marker>
              <Marker position={[data.lat, data.lng]}>
                <Popup>
                  {data.nom} se trouve : {street}
                </Popup>
              </Marker>
              <Marker position={[data.lat2, data.lng2]}>
                <Popup>
                  destination de {data.nom}
                </Popup>
              </Marker>
            </>
          )}
        </MapContainer>
        {!buttonClicked && (
          <button className="button" onClick={afficherItineraire}>
            {routingControl ? "Masquer l'itinéraire" : "Afficher l'itinéraire"}
          </button>
        )}
      </div>
    );
  } else if (data) {
    return (
      <div style={{ width: '100%', height: '100%' }}>
        <MapContainer center={[data.lat, data.lng]} zoom={20} scrollWheelZoom={false} style={{ width: '100%', height: '100%' }} ref={mapRef}>
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
          <Marker position={[data.lat, data.lng]}>
            <Popup>
              {data.nom} se trouve : {street}
            </Popup>
          </Marker>
        </MapContainer>
      </div>
    );
  } else {
    return <div>Loading...</div>;
  }
}
