import React, { useState } from 'react';
import "../stylesheets/ContactsUrgence.css"
import { IconContext } from "react-icons";
import { RiDeleteBin6Line } from "react-icons/ri";
import { FaPencilAlt } from "react-icons/fa";

function ContactItem({ index, contact, deleteContact, updateContact }) {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContact, setEditedContact] = useState(contact);

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedContact({ ...editedContact, [name]: value });
  };

  const saveEdit = () => {
    updateContact(index, editedContact);
    setIsEditing(false);
  };

  return (
    <div className='contact-content'>
      <div className='contact-header'>
        <p className='contact-nb'>Contact n°{index + 1}
          <button className="btn-contact" onClick={() => setIsEditing(true)}><FaPencilAlt /></button>
          <button className="btn-contact" onClick={() => deleteContact(index)}><RiDeleteBin6Line /></button>
        </p>
      </div>
      {isEditing ? (
        <div>
          <div className="form-group">
            <label>Nom :</label>
            <input
              name="name"
              className='input-contact-edit'
              value={editedContact.name}
              onChange={handleEditChange}
            />
          </div>
          <div className="form-group">
            <label>Numéro :</label>
            <input
              name="phone"
              className='input-contact-edit'
              value={editedContact.phone}
              onChange={handleEditChange}
            />
          </div>
          <button className="submit-contact" onClick={saveEdit}>Sauvegarder</button>
        </div>
      ) : (
        <div>
          <div className="form-group">
            <label>Nom :</label>
            <input
              name="name"
              className='input-contact-unedit'
              value={contact.name}
              readOnly
            />
          </div>
          <div className="form-group">
            <label>Numéro :</label>
            <input
              name="phone"
              className='input-contact-unedit'
              value={contact.phone}
              readOnly
            />
          </div>
        </div>
      )}

    </div>
  );
}

export default ContactItem;
