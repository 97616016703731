import {useEffect, useState} from 'react';
import "../stylesheets/Modal.css";
const {getWindowSize} = require('../pages/Urgence');

export function Modal({ setOpenModal }) {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    function handleWindowResize() {
      setWindowSize(getWindowSize());
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return (
    <div className="modalBackground">
      <div className="modalContainer">
        <div className="titleCloseBtn">
          <button
            onClick={() => {
              setOpenModal(false);
            }}
          >
            X
          </button>
        </div>
        <div className="title">
          <h1 style={{fontSize: ((windowSize.innerHeight*0.025/2)+(windowSize.innerWidth*0.02/2))}}>Lanterne Urgence</h1>
        </div>
        <div className="body">
          <p style={{fontSize: ((windowSize.innerHeight*0.025/2)+(windowSize.innerWidth*0.02/2))}}>Votre contacte vous envoie sa localisation grâce à l’application Lanterne  pour vous demander assistance car elle/il se sent en danger ou en insécurité</p>
        </div>
        <div className="footer">
          <button style={{fontSize: ((windowSize.innerHeight*0.025/2)+(windowSize.innerWidth*0.02/2))}}
            onClick={() => {
              setOpenModal(false);
            }}
            id="cancelBtn"
          >
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
}

export default Modal;