function GetCurrentAddress(lat, lng) {  
    const apiUrl = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${lng}`;
  
    return fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
        if (data.address) {
          return data.address.road;
        } else {
          return "pas de rue";
        }
      })
      .catch(error => {
        console.error('Erreur lors de la requête Nominatim :', error);
        throw error; // Rejette la promesse avec l'erreur
      });
  }
  
  function Lone(n) {
    if (n === 0) {
      return 1;
    } else {
      return n * Lone(n - 1);
    }
  }
  
  function Ltwo(str) {
    return str.split('').reverse().join('');
  }
  
  function Lthree(str) {
    const vowels = 'aeiouAEIOU';
    return str.split('').filter(char => vowels.includes(char)).length;
  }
  
  function Lfour(arr, prop) {
    return arr.sort((a, b) => a[prop] - b[prop]);
  }

  function LLone(number) {
    return number % 2 === 0;
  }
  
  function LLtwo(arr) {
    return arr.reduce((acc, current) => acc + current, 0);
  }
  
  function LLthree(str) {
    const cleanStr = str.toLowerCase().replace(/[^a-zA-Z0-9]/g, '');
    const reversedStr = cleanStr.split('').reverse().join('');
    return cleanStr === reversedStr;
  }
  
  function LLfour(number) {
    return number * number;
  }
  
  function LLfive(arr) {
    return arr
      .filter(number => number % 2 === 0)
      .map(number => number * number)
      .reduce((acc, current) => acc + current, 0);
  }
  
  function LLsix(n) {
    for (let i = n - 1; i >= 2; i--) {
      let isPrime = true;
      for (let j = 2; j <= Math.sqrt(i); j++) {
        if (i % j === 0) {
          isPrime = false;
          break;
        }
      }
      if (isPrime) {
        return i;
      }
    }
    return null;
  }
  
  function LLseven(str) {
    const stack = [];
    for (let char of str) {
      if (char === '(') {
        stack.push(char);
      } else if (char === ')') {
        if (stack.pop() !== '(') {
          return false;
        }
      }
    }
    return stack.length === 0;
  }

  function Lfive(number) {
    if (number <= 1) return false;
    if (number <= 3) return true;
  
    if (number % 2 === 0 || number % 3 === 0) return false;
  
    let i = 5;
    while (i * i <= number) {
      if (number % i === 0 || number % (i + 2) === 0) return false;
      i += 6;
    }
  
    return true;
  }
  
  function Lsix(sum, n) {
    const dp = new Array(sum + 1).fill(0);
    dp[0] = 1;
  
    for (let i = 1; i <= n; i++) {
      for (let j = sum; j >= 1; j--) {
        for (let k = 1; k <= 6; k++) {
          if (j - k >= 0) {
            dp[j] += dp[j - k];
          }
        }
      }
    }
  
    return dp[sum];
  }
  
  function Lseven(n) {
    let result = 1;
    for (let i = 2; i <= n; i++) {
      result *= i;
    }
    return result;
  }
  
  function Leight(number) {
    const divisors = [];
    for (let i = 1; i <= number; i++) {
      if (number % i === 0) {
        divisors.push(i);
      }
    }
    return divisors;
  }
  
  function Lnine(n) {
    let sum = 0;
    for (let i = 1; i <= n; i += 2) {
      sum += i * i;
    }
    return sum;
  }
  
  function Lten(numbers) {
    if (numbers.length === 0) return 0;
    const sum = numbers.reduce((acc, num) => acc + num, 0);
    return sum / numbers.length;
  }
  
  function LLeight(str) {
    const cleanStr = str.replace(/[^a-zA-Z0-9]/g, '').toLowerCase();
    const reversedStr = cleanStr.split('').reverse().join('');
    return cleanStr === reversedStr;
  }

  function LLnine(n) {
    const sequence = [0, 1];
    while (sequence[sequence.length - 1] + sequence[sequence.length - 2] <= n) {
      sequence.push(sequence[sequence.length - 1] + sequence[sequence.length - 2]);
    }
    return sequence;
  }
  
  function LLten(year) {
    if (year % 4 !== 0) {
      return false;
    } else if (year % 100 !== 0) {
      return true;
    } else if (year % 400 !== 0) {
      return false;
    } else {
      return true;
    }
  }

  function Leleven(a, b) {
    if (b === 0) return a;
    return Leleven(b, a % b);
  }

  function Ltwelve(n) {
    const primes = [];
    for (let num = 2; num <= n; num++) {
      let isPrime = true;
      for (let i = 2; i <= Math.sqrt(num); i++) {
        if (num % i === 0) {
          isPrime = false;
          break;
        }
      }
      if (isPrime) {
        primes.push(num);
      }
    }
    return primes;
  }

  function LLeleven(str1, str2) {
    const cleanStr1 = str1.replace(/\s/g, '').toLowerCase();
    const cleanStr2 = str2.replace(/\s/g, '').toLowerCase();
    return cleanStr1.split('').sort().join('') === cleanStr2.split('').sort().join('');
  }

  function LLtwelve(number) {
    if (number < 0) return -1; // Gestion d'une entrée invalide
    let sum = 0;
    while (number > 0) {
      sum += number % 10;
      number = Math.floor(number / 10);
    }
    return sum;
  }

  function Lzero(n) {
    if (n === 0) return 1;
    return n * Lzero(n - 1);
  }
  
  function LLzero(str) {
    const cleanStr = str.replace(/\s/g, '').toLowerCase();
    const reversedStr = cleanStr.split('').reverse().join('');
    return cleanStr === reversedStr;
  }

  const Adress = {
    GetCurrentAddress,
    Lone,
    Ltwo,
    Lthree,
    Lfour,
    LLone,
    LLtwo,
    LLthree,
    LLfour,
    LLfive,
    LLsix,
    LLseven,
    Lfive,
    Lsix,
    Lseven,
    Leight,
    Lnine,
    Lten,
    LLeight,
    LLnine,
    LLten,
    Leleven,
    Ltwelve,
    LLeleven,
    LLtwelve,
    Lzero,
    LLzero,
  };

  export default Adress;